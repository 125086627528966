import { sLocalStorage } from "@solvari/common-fe/helpers";

import dayjs from "@/vue/utils/dayjs";

import "@/platform/components/s-visited-pages/sVisitedPages.css";

interface VisitedPage {
  date: string;
  image: string;
  title: string;
  url: string;
}
declare global {
  interface LocalStorage {
    lastVisitedPages: VisitedPage[];
  }
}

const visitedPages = sLocalStorage.getItem("lastVisitedPages") ?? [];

const visitedPagesTemplate: HTMLTemplateElement = document.querySelector(
  "#s-visited-pages__card",
)!;
const parent = document.querySelector(".s-visited-pages__cards");
const originalUrl = document.querySelector('meta[property="og:url"]');

if (originalUrl?.getAttribute("content")) {
  let exists = false;

  visitedPages.forEach((value, index) => {
    if (
      value.url ===
      document.querySelector('meta[property="og:url"]')!.getAttribute("content")
    ) {
      exists = true;
    }

    const daysAgo: number = dayjs().diff(dayjs(value.date), "days");

    if (daysAgo >= 7) {
      visitedPages.splice(index);
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!exists) {
    if (visitedPages.length >= 3) {
      visitedPages.shift();
    }

    const newStorageItem: VisitedPage = {
      title:
        document
          .querySelector('meta[property="og:title"]')!
          .getAttribute("content") || "",
      url:
        document
          .querySelector('meta[property="og:url"]')!
          .getAttribute("content") || "",
      image:
        document
          .querySelector('meta[property="og:image"]')!
          .getAttribute("content") || "",
      date: new Date().toISOString(),
    };

    visitedPages.push(newStorageItem);
    sLocalStorage.setItem("lastVisitedPages", visitedPages);
  }

  if (visitedPages.length > 0 && parent) {
    document
      .querySelector(".s-visited-pages")!
      .setAttribute("style", "display: block;");

    visitedPages.reverse().forEach((value: VisitedPage) => {
      const clonedTemplate: DocumentFragment =
        visitedPagesTemplate.content.cloneNode(true) as DocumentFragment;

      const linkElement = clonedTemplate.querySelector<HTMLAnchorElement>(
        ".s-visited-pages__card__link",
      )!;
      linkElement.href = value.url;

      clonedTemplate
        .querySelector(".s-visited-pages__card__image")!
        .setAttribute("style", `background-image: url('${value.image}')`);
      clonedTemplate.querySelector(
        ".s-visited-pages__card__title",
      )!.textContent = value.title;
      clonedTemplate
        .querySelector(".s-visited-pages__card__time")!
        .setAttribute("data-date", value.date);
      clonedTemplate.querySelector(
        ".s-visited-pages__card__time span",
      )!.textContent = dayjs(value.date).fromNow();

      parent.appendChild(clonedTemplate);
    });
  }
}
